import * as React from "react"
import type { HeadFC } from "gatsby"
import { Script } from 'gatsby';

import Card from 'react-bootstrap/Card'
import { Container, Row, Col, CardGroup } from 'react-bootstrap';

import heroDemo from "../images/hero-demo.png";
import logoRound from "../images/logo_round.png";

//import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/theme.scss';
import { ReactNode } from "react";
import Section from "../components/section";

const CardItem = (props: { title: string, img?: string, children?: ReactNode } ) => {
  return (
    <Card className="shadow">
      { props.img !== undefined && <Card.Img variant="top" src={props.img} /> }
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text>
          {props.children}
        </Card.Text>
        {/* <Button variant="primary">Go somewhere</Button> */}
      </Card.Body>
    </Card>
  );
}

const Feature = (props: { title: string, children?: ReactNode}) => {
  return <div className="my-3 text-start">
    <div className="mb-1 fw-bold fs-2">{props.title}</div>
    <div>{props.children}</div>
  </div>
}

const IndexPage = () => {
  return (
    <main>
      <Section>
        <Container className="text-center">
          <Col className="col-lg-6 mx-auto">
            <img className="d-block mx-auto mb-4" src={logoRound} alt="" width="72" height="72" />
            <h1 className="display-4 fw-bold">DragonSight</h1>
            <p className="lead mb-4">Giving you immediate notifications for your game from all across the web.</p>
            <div>
              <a href="https://go.dragonsight.app/login" className="btn btn-primary btn-lg px-4 me-sm-3" target="_blank">Sign in</a>
              <a href="https://go.dragonsight.app/register" className="btn btn-lg px-4 me-sm-3" target="_blank">Register</a>
            </div>
          </Col>
        </Container>
      </Section>
      <Section background="#2e1313">
        <Container className="">
          <Row>
            <Col className="col-md-6 col-sm-12">
              <img className="img-fluid rounded-3 mb-4" style={{ maxHeight: "30vh"}} src={heroDemo} alt="Stream announcement example" />
            </Col>
            <Col className="col-md-6 col-sm-12">
              <Feature title="Let our bot do the heavy lifting">DragonSight notifies you when new streams or content is found, no more searching the internet endlessly.</Feature>
              <Feature title="Discord integration">Manage your notifications directly from Discord with our DragonSight Bot.</Feature>
              <Feature title="See everything">As soon as a streamer is on, you get your notifications, no waiting to see that kill streak.</Feature>
              {/* <p className="lead mb-4"></p>
              <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
                <a href="https://forms.gle/3u6iHcaBw7Dct3Rs7" className="btn btn-secondary btn-lg px-4 me-sm-3" target="_blank">Take the survey</a>
                <a href="https://app.dragonsight.io" className="btn btn-primary btn-lg px-4 me-sm-3" target="_blank">Go to the app</a>
              </div> */}
            </Col>
          </Row>
        </Container>
      </Section>
      <Section title="Pricing">
        <Container className="text-center">
          <Row className="align-items-md-center mt-2">
            <Col className="col-md-6 col-sm-12">
              <h3 className="display-5 fw-bold">$10 USD</h3>
              <p>per month / per game</p>
            </Col>
            <Col className="col-md-6 col-sm-12">
              <p>This includes access to our notification bot that will post when someone streams your game, access to our services to manage your ever-growing list of press and influencers.</p>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section title="Roadmap">
        <p className="mb-4 text-center">We are constantly evolving. See what is coming up.</p>
        <Container>
          <Row>
            <Col className="col-lg-4">
              <CardItem title="Manage press list">
                <div>Store, sort and manage your list of influencers and press and who you have given keys too.</div>
              </CardItem>
            </Col>
            <Col className="col-lg-4">
              <CardItem title="Reviews">
                <div>Will notify you of popular gaming websites posting about your game.</div>
              </CardItem>
            </Col>
            <Col className="col-lg-4">
              <CardItem title="Email notifications">
                <div>
                  Get sent emails when videos are posted or someone goes live.
                </div>
              </CardItem>
            </Col>
          </Row>
        </Container>
      </Section>
      <Container>
        <footer className="py-3 my-4">
          <p className="text-center text-muted border-top pt-2">&copy; 2022-{new Date().getFullYear()} A Few Dragons Pty Ltd. All rights reserved.</p>
        </footer>
      </Container>
      <Script id="hs-script-loader" src="//js-na1.hs-scripts.com/24213595.js" async defer />
    </main>
  )
}

export default IndexPage

export const Head: HeadFC = () => {
  return <>
    <title>DragonSight</title>
  </>
}
